import React, { useEffect, useState } from "react"
import Sheet from "react-modal-sheet"
import { motion } from "framer-motion"
import get from "lodash.get"
import { Button, Container, Flex, Input, Text, useColorModeValue } from "@chakra-ui/react"
import { CheckIcon } from "@chakra-ui/icons"
import theme from "../../theme"

type SearchableSelectProps = {
  options: any[]
  value?: any
  multiple?: boolean
  disabled?: boolean
  onChange?: any
  getOptionValue: any
  getOptionLabel: any
  getSelectedOptionLabel?: any
  getOptionFilter?: any
  placeholder?: string
  searchPlaceholder?: string
  outerSx?: any
}

const SheetContainer = Sheet.Container as any
const SheetHeader = Sheet.Header as any
const SheetBackdrop = Sheet.Backdrop as any

export const SearchableSelect: React.FC<SearchableSelectProps> = ({
  options = [],
  value,
  placeholder,
  searchPlaceholder,
  onChange,
  getOptionValue,
  getOptionFilter,
  getOptionLabel,
  getSelectedOptionLabel,
  disabled,
  outerSx = {},
  children,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const [filterText, setFilterText] = useState<string>("")

  const filteredOptions = getOptionFilter
    ? getOptionFilter(options, filterText)
    : options.filter((option) =>
        getOptionValue(option).toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
      )
  const selectedOption = options.find((opt) => getOptionValue(opt) === value)

  const bg = useColorModeValue("white", "gray.800")
  const textColor = useColorModeValue("blackAlpha.400", "whiteAlpha.400")
  const buttonBg = useColorModeValue("gray.200", "gray.600")
  const buttonBgHover = useColorModeValue("gray.100", "gray.700")

  useEffect(() => {
    if (value && options?.length && !options.find((opt) => getOptionValue(opt) === value)) {
      onChange()
    }
  }, [value, options])

  return (
    <>
      {children && typeof children === "function" ? (
        children({ selectedOption, setOpen })
      ) : (
        <Button
          type="button"
          onClick={() => setOpen(true)}
          sx={{
            width: "100%",
            px: 4,
            py: 3,
            boxShadow: "none",
            bg: buttonBg,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "Inter",
            height: "40px",
            justifyContent: "flex-start",
            ":hover": {
              bg: buttonBgHover,
            },
            ...outerSx,
          }}
        >
          <Text
            sx={{
              fontFamily: "Rubik",
              color: selectedOption ? "inherit" : textColor,
            }}
          >
            {selectedOption
              ? getSelectedOptionLabel
                ? getSelectedOptionLabel(selectedOption)
                : getOptionLabel(selectedOption)
              : placeholder}
          </Text>
        </Button>
      )}
      <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[600, 0]}>
        <SheetContainer style={{ background: get(theme.colors, bg) }}>
          <SheetHeader />
          <Sheet.Content>
            <Container maxW="container.lg">
              <Flex sx={{ flexDirection: "column", py: 6 }}>
                <Input
                  variant="filled"
                  mb={4}
                  type="text"
                  placeholder={searchPlaceholder}
                  value={filterText}
                  onChange={(e: any) => setFilterText(e.target.value)}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
                <Flex sx={{ flexDirection: "column", overflow: "auto", height: 480 }}>
                  {filteredOptions.map((option) => (
                    <motion.a
                      key={option.value}
                      whileHover={{ transition: { duration: 1 }, cursor: "pointer" }}
                      whileTap={{ scale: 0.95 }}
                      onClick={(e) => {
                        e.preventDefault()
                        onChange(getOptionValue(option))
                        setOpen(false)
                      }}
                    >
                      <Flex
                        sx={{
                          py: 3,
                          transition: "all .2s ease-in-out",
                          ":hover": {
                            cursor: "pointer",
                            transform: "scale(0.98)",
                            opacity: 0.75,
                          },
                        }}
                      >
                        {value === getOptionValue(option) && (
                          <CheckIcon mr={2} w={4} h={4} color="blue.500" />
                        )}
                        <Text size="6" sx={{ fontWeight: 600 }}>
                          {getOptionLabel(option)}
                        </Text>
                      </Flex>
                    </motion.a>
                  ))}
                </Flex>
              </Flex>
            </Container>
          </Sheet.Content>
        </SheetContainer>

        <SheetBackdrop onTap={() => setOpen(false)} />
      </Sheet>
    </>
  )
}
