import React, { Suspense, useState, useMemo, useEffect } from "react"
import {
  Head,
  Link,
  useQuery,
  useInfiniteQuery,
  useSession,
  useMutation,
  useRouter,
  BlitzPage,
} from "blitz"
import { motion } from "framer-motion"
import Layout from "app/core/layouts/Layout"
import getSubsInfinite from "app/subs/queries/getSubsInfinite"
import getSubsAmount from "app/subs/queries/getSubsAmount"
import getSubsCount from "app/subs/queries/getSubsCount"
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Link as ChakraLink,
  Skeleton,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react"
import { AddIcon } from "@chakra-ui/icons"
import SubRow, { Skeleton as SkeletonSubRow } from "app/subs/components/SubRow"
import SubsOrderSelector from "app/subs/components/SubsOrderSelector"
import CategoriesPicker from "app/categories/components/CategoriesPicker"
import { AnimatedIconButton } from "app/core/components/AnimatedIconButton"
import { CurrencyPicker } from "app/core/components/CurrencyField"
import { Switch } from "app/core/components/Switch"
import updateMainCurrency from "app/auth/mutations/updateMainCurrency"
import saveAnonymousData from "app/subs/mutations/saveAnonymousData"
import { formatCurrency } from "utils/format"
import { capitalizeFirstLetter } from "utils/string"
import { HiSortDescending } from "react-icons/hi"
import getCurrentUser from "app/users/queries/getCurrentUser"
import { AnimatedButton } from "app/core/components/AnimatedButton"

export const SkeletonSubList = () => (
  <Box as="ul" sx={{ minHeight: "66vh", alignSelf: "stretch", m: 0, p: 0 }}>
    {[...Array(9)].map((_, i) => (
      <li key={i}>
        <SkeletonSubRow />
      </li>
    ))}
  </Box>
)

export const SubsList = ({ categoryIds, billingPeriodFilter, orderBy, now }) => {
  const router = useRouter()
  const [saveAnonymousDataMutation] = useMutation(saveAnonymousData)
  let customOrderBy
  if (orderBy?.due) {
    customOrderBy = orderBy
    orderBy = undefined
  }

  const [groupedSubs, { refetch }] = useInfiniteQuery(
    getSubsInfinite,
    () => ({
      orderBy,
      customOrderBy,
      where: {
        ...(categoryIds.length
          ? {
              OR: categoryIds.map((cId) => ({
                categories: {
                  some: {
                    id: cId,
                  },
                },
              })),
            }
          : {}),
        state: "VALIDATED",
      },
      include: {
        product: true,
      },
      billingPeriodFilter,
    }),
    {
      getNextPageParam: (lastGroup) => lastGroup.nextPage,
    }
  )

  useEffect(() => {
    if (typeof window !== "undefined" && router.query["redirect-reason"] === "auth") {
      router.push("/", "/", { shallow: true })
      saveAnonymousDataMutation().then(() => refetch())
    }
  }, [])

  return (
    <Box
      as="ul"
      sx={{
        minHeight: "66vh",
        alignSelf: "stretch",
        m: 0,
        p: 0,
        pb: 86,
      }}
    >
      {groupedSubs.map((group, i) => (
        <React.Fragment key={i}>
          {group.subs.map((sub) => (
            <li key={sub.id}>
              <Link
                href={{
                  pathname: `/subscriptions/${sub.id}`,
                  query: {
                    name: sub.name,
                    amount: sub.amount,
                    currency: sub.currency,
                    cycleUnit: sub.cycleUnit,
                    ...(sub?.product?.bg ? { productBg: sub.product.bg } : {}),
                    ...(sub?.product?.textColor ? { productTextColor: sub.product.textColor } : {}),
                  },
                }}
                passHref
              >
                <a>
                  <SubRow sub={sub} />
                </a>
              </Link>
            </li>
          ))}
        </React.Fragment>
      ))}
    </Box>
  )
}

const SubsAmount = ({ categoryIds, billingPeriodFilter, now }) => {
  const [user, { refetch: refetchUser }] = useQuery(getCurrentUser, { authIsMandatory: false })
  const session = useSession({ suspense: false })
  const [updateMainCurrencyMutation] = useMutation(updateMainCurrency)
  const [subsCountData] = useQuery(getSubsCount, {})

  const currency = (user?.currency || session.mainCurrency || "USD") as string
  const [res] = useQuery(getSubsAmount, {
    where: {
      state: "VALIDATED",
      AND: [
        {
          ...(categoryIds.length
            ? {
                OR: categoryIds.map((categoryId) => ({
                  categories: {
                    some: {
                      id: categoryId,
                    },
                  },
                })),
              }
            : {}),
        },
        {
          OR: [
            {
              endingAt: null,
            },
            {
              endingAt: {
                gt: now,
              },
            },
          ],
        },
      ],
    },
    billingPeriodFilter,
    currency,
  })

  const unhandledCurrency = !res?.amount && res?.amount !== 0 && subsCountData.count > 0

  const getFormattedCurrency = () => {
    try {
      return formatCurrency(res.amount, currency ? { currency } : {})
    } catch (error) {
      return `${currency} ${res.amount || 0}`
    }
  }

  return (
    <Flex sx={{ flexDirection: "column", alignItems: "center", my: 6 }}>
      <Text fontSize={unhandledCurrency ? "xl" : "5xl"} sx={{ fontWeight: "bold", mr: 1 }}>
        {unhandledCurrency ? "unhandled main currency" : getFormattedCurrency()}
      </Text>
      {(!session?.userId || unhandledCurrency) && (
        <CurrencyPicker
          onChange={async (newCurrency) => {
            await updateMainCurrencyMutation({ data: newCurrency })
            refetchUser()
          }}
        >
          {({ setOpen }) => (
            <ChakraLink
              color="gray.400"
              onClick={() => {
                setOpen(true)
              }}
            >
              change currency
            </ChakraLink>
          )}
        </CurrencyPicker>
      )}
    </Flex>
  )
}

const SubsPage: BlitzPage = ({ cookies }: any) => {
  const router = useRouter()
  const session = useSession({ suspense: false })
  const [billingPeriodFilter, setBillingPeriodFilter] = useState("month")
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<any[]>([])
  const [selectedOrder, setSelectedOrder] = useState()

  const bg = useColorModeValue(
    "linear-gradient(rgba(255, 255, 255, 0.001), rgba(255, 255, 255))",
    "linear-gradient(rgba(18, 21, 21, 0.001), rgba(18, 21, 21))"
  )

  const anonymousWarningBgColor = useColorModeValue("gray.200", "gray.600")
  const anonymousWarningTextColor = useColorModeValue("gray.600", "gray.400")

  const now = useMemo(() => new Date(), [])

  return (
    <Container maxW="container.lg">
      <Head>
        <title>Subscriptions</title>
      </Head>

      <Flex
        as="main"
        sx={{
          pt: 9,
          alignSelf: "stretch",
          overflow: "auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Suspense
          fallback={<Skeleton sx={{ height: "72px", width: 180, my: 6, borderRadius: 999 }} />}
        >
          <Flex>
            <SubsAmount
              now={now}
              categoryIds={selectedCategoryIds}
              billingPeriodFilter={billingPeriodFilter}
            />
          </Flex>
        </Suspense>

        <Switch
          id="billing-period"
          defaultValue="month"
          onChange={(key) => setBillingPeriodFilter(key)}
          sx={{ mb: 4 }}
        >
          <Switch.Item id="week">Weekly</Switch.Item>
          <Switch.Item id="month">Monthly</Switch.Item>
          <Switch.Item id="year">Yearly</Switch.Item>
        </Switch>

        {!session?.userId && !session?.isLoading && (
          <Flex
            my={4}
            py={4}
            px={6}
            sx={{ bgColor: anonymousWarningBgColor, borderRadius: 999999 }}
          >
            <Text as="p" color={anonymousWarningTextColor} sx={{ textAlign: "center" }}>
              You are using an anonymous session <strong>your data is not safe</strong>.{" "}
              <Link href="/signup">
                <Tooltip label="Sign up to secure your data">
                  <Link href="/signup" passHref>
                    <AnimatedButton as="a" colorScheme="primary" sx={{ ml: 2 }} size="sm">
                      Sign up
                    </AnimatedButton>
                  </Link>
                </Tooltip>
              </Link>
            </Text>
          </Flex>
        )}

        <Flex sx={{ alignSelf: "stretch", justifyContent: "space-between", alignItems: "center" }}>
          <Suspense
            fallback={
              <Flex sx={{ alignSelf: "inherit", alignItems: "center", py: 2 }}>
                <Heading fontSize="2xl" sx={{ pr: 1 }}>
                  All Subscriptions
                </Heading>
                <Text sx={{ width: 6, height: 6 }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Text>
              </Flex>
            }
          >
            <CategoriesPicker
              onChange={(categoryIds) =>
                categoryIds ? setSelectedCategoryIds(categoryIds) : setSelectedCategoryIds([])
              }
            >
              {({ values }) => (
                <Flex sx={{ alignItems: "center", py: 2 }}>
                  <Heading
                    sx={{
                      pr: 1,
                      fontSize: 22,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {values.map((v) => v?.id).includes("all") || values.length === 0
                      ? "All Subscriptions"
                      : values.map((v) => capitalizeFirstLetter(v?.name)).join(", ")}
                  </Heading>
                  <Text sx={{ width: 6, height: 6 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Text>
                </Flex>
              )}
            </CategoriesPicker>
          </Suspense>
          <Suspense fallback={<></>}>
            <SubsOrderSelector onChange={setSelectedOrder}>
              {() => <Icon as={HiSortDescending} w={6} h={6} color="gray.300" />}
            </SubsOrderSelector>
          </Suspense>
        </Flex>

        <Suspense fallback={<SkeletonSubList />}>
          <SubsList
            categoryIds={selectedCategoryIds}
            billingPeriodFilter={billingPeriodFilter}
            orderBy={selectedOrder}
            now={now}
          />
        </Suspense>

        <Flex
          sx={{
            bg,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            height: 16,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AnimatedIconButton
            colorScheme="primary"
            fontSize="27px"
            icon={<AddIcon />}
            onClick={() => router.push("/subscriptions/new")}
            sx={{
              mb: 8,
              p: 4,
              height: "58px",
              width: "58px",
              borderRadius: "50%",
              boxShadow:
                "rgb(125 125 125 / 50%) 0px 4px 0px -5px, rgb(125 125 125 / 50%) 0px 11px 20px -8px",
            }}
          />
        </Flex>
      </Flex>
    </Container>
  )
}

SubsPage.getLayout = (page) => <Layout title={"Subscriptions"}>{page}</Layout>

export default SubsPage
