import { useEffect, useState } from "react"
import Sheet from "react-modal-sheet"
import { motion } from "framer-motion"
import { useMutation } from "blitz"
import get from "lodash.get"
import { Container, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react"
import updateCurrentUser from "app/auth/mutations/updateCurrentUser"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"

import { capitalizeFirstLetter } from "../../../utils/string"
import theme from "../../theme"

const SheetContainer = Sheet.Container as any
const SheetHeader = Sheet.Header as any
const SheetBackdrop = Sheet.Backdrop as any

type SubsOrderSelectorSelectorProps = {
  children: ({ orderName: String }) => React.ReactNode
  onChange: (value: any) => void
}

const orders = [
  { name: "Creation date", id: "id" },
  { name: "Due date", id: "due" },
  { name: "Name", id: "name" },
  { name: "Price", id: "baseAmount" },
]

const AnimatedFlex = motion(Flex)

const OrderRow = ({ id, name, onSelect, value }) => {
  return (
    <Flex
      key={id}
      sx={{
        justifyContent: "space-between",
        py: 3,
      }}
    >
      <Flex sx={{ flex: 1, alignItems: "center", justifyContent: "space-between" }}>
        <Text size="6" pr="2" sx={{ fontWeight: 600 }}>
          {capitalizeFirstLetter(name)}
        </Text>

        <Flex>
          <AnimatedFlex
            {...(value?.[id] === "asc" ? { bg: "blue.400" } : {})}
            sx={{
              w: 8,
              h: 8,
              mr: 3,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              transition: "all .2s ease-in-out",
              ":hover": {
                cursor: "pointer",
                transform: "scale(0.98)",
                opacity: 0.75,
              },
            }}
            whileHover={{ transition: { duration: 1 }, cursor: "pointer" }}
            whileTap={{ scale: 0.95 }}
            onClick={(e) => {
              e.preventDefault()
              onSelect({ [id]: "asc" })
            }}
          >
            <Icon
              as={IoIosArrowDown}
              w={6}
              h={6}
              {...(value?.[id] === "asc" ? { color: "white" } : { color: "gray.300" })}
            />
          </AnimatedFlex>
          <AnimatedFlex
            {...(value?.[id] === "desc" ? { bg: "blue.500" } : {})}
            sx={{
              w: 8,
              h: 8,
              ml: 3,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              transition: "all .2s ease-in-out",
              ":hover": {
                cursor: "pointer",
                transform: "scale(0.98)",
                opacity: 0.75,
              },
            }}
            whileHover={{ transition: { duration: 1 }, cursor: "pointer" }}
            whileTap={{ scale: 0.95 }}
            onClick={(e) => {
              e.preventDefault()
              onSelect({ [id]: "desc" })
            }}
          >
            <Icon
              as={IoIosArrowUp}
              w={6}
              h={6}
              {...(value?.[id] === "desc" ? { color: "white" } : { color: "gray.300" })}
            />
          </AnimatedFlex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const SubsOrderSelector: React.FC<SubsOrderSelectorSelectorProps> = ({ onChange, children }) => {
  const user = useCurrentUser()
  const [updateCurrentUserMutation] = useMutation(updateCurrentUser, {})
  const [isOpen, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<any>()

  useEffect(() => {
    if (user?.defaultOrderBy && !value) {
      setValue(user?.defaultOrderBy)
      onChange(user?.defaultOrderBy)
    }
  }, [user?.defaultOrderBy])

  const getOrderName = () => {
    const order = orders.find((c) => c.id === value)

    if (order) {
      return order.name
    }

    return null
  }

  const bg = useColorModeValue("white", "gray.800")

  return (
    <>
      <Text
        as="a"
        sx={{
          display: "inline-flex",
          alignSelf: "stretch",
          alignItems: "center",
          ":hover": { cursor: "pointer" },
        }}
        onClick={(e) => {
          e.preventDefault()
          setOpen(true)
        }}
      >
        {children({ orderName: getOrderName() })}
      </Text>
      <Sheet
        isOpen={isOpen}
        onClose={() => {
          setOpen(false)
        }}
        snapPoints={[330, 0]}
      >
        <SheetContainer style={{ backgroundColor: get(theme.colors, bg) }}>
          <SheetHeader />
          <Sheet.Content>
            <Container maxW="container.lg">
              <Flex sx={{ flexDirection: "column", py: 6 }}>
                {orders.map((order) => (
                  <OrderRow
                    key={order.id}
                    {...order}
                    value={value}
                    onSelect={async (newOrder) => {
                      setValue(newOrder)
                      onChange(newOrder)
                      if (user?.id) {
                        await updateCurrentUserMutation({
                          data: {
                            defaultOrderBy: newOrder,
                          },
                        })
                        setOpen(false)
                      }
                    }}
                  />
                ))}
              </Flex>
            </Container>
          </Sheet.Content>
        </SheetContainer>

        <SheetBackdrop
          onTap={() => {
            setOpen(false)
          }}
        />
      </Sheet>
    </>
  )
}

export default SubsOrderSelector
