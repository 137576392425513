import React, { createContext, useContext, useState, useEffect, useMemo } from "react"
import { motion } from "framer-motion"
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react"

const AnimatedBox = motion(Box)
const AnimatedText = motion<any>(Text)

type SwitchContextProps = {
  selectedItem?: string
  setSelectedItem: (selectedItem: string) => void
}

const SwitchContext = createContext<SwitchContextProps | null>(null)

function useSwitchContext() {
  const context = useContext(SwitchContext)
  if (!context) {
    throw new Error(`Switch compound components cannot be rendered outside the Switch component`)
  }
  return context
}

function Item({ id, children }) {
  const { selectedItem, setSelectedItem } = useSwitchContext()

  const isActive = selectedItem === id
  const textColor = useColorModeValue("gray.900", "gray.300")

  return (
    <AnimatedText
      key={id}
      id={id}
      as="a"
      onClick={(e) => {
        e.preventDefault()
        setSelectedItem(id)
      }}
      animate={isActive ? "active" : "inactive"}
      size="3"
      sx={{
        color: isActive ? "#5E5757" : "#fff",
        px: 3,
        py: 2,
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      {children}
    </AnimatedText>
  )
}

export function Switch({ id, children, defaultValue, onChange, sx = {}, ...props }) {
  const [isInit, setIsInit] = useState<boolean>(true)
  const [selectedItem, setSelectedItem] = useState<string>()
  const [selectedItemDetails, setSelectedItemDetails] = useState<
    | {
        width?: number
        left?: number
      }
    | undefined
  >()
  const value = useMemo(() => ({ selectedItem, setSelectedItem }), [selectedItem])

  useEffect(() => {
    if (defaultValue && !selectedItem) {
      setSelectedItem(defaultValue)
    }
  }, [])

  useEffect(() => {
    if (selectedItem) {
      if (!isInit) {
        onChange(selectedItem)
      } else {
        setIsInit(false)
      }

      if (typeof window !== "undefined") {
        const selectedDomItem = window.document.querySelector(`#${id} #${selectedItem}`)

        setSelectedItemDetails({
          width: (selectedDomItem as any)?.offsetWidth,
          left: (selectedDomItem as any)?.offsetLeft,
        })
      }
    }
  }, [selectedItem])

  const spring = {
    type: "spring",
    stiffness: 400,
    damping: 24,
  }

  const bg = useColorModeValue("gray.200", "gray.600")

  return (
    <SwitchContext.Provider value={value}>
      <Box id={id} sx={{ position: "relative", ...sx }} {...props}>
        <Flex
          sx={{
            background: "linear-gradient(#58A2FB, #0073FB);",
            borderRadius: 9999,
            boxShadow: "0 3.27px 5px rgba(0, 0, 0, 0.25)",
          }}
        >
          {children}
        </Flex>
        <Flex
          sx={{
            zIndex: 1,
            position: "absolute",
            top: 0,
            borderRadius: 9999,
          }}
        >
          {children}
        </Flex>
        {!!selectedItemDetails?.width && (
          <AnimatedBox
            initial={false}
            animate={{ width: selectedItemDetails?.width || 0, left: selectedItemDetails?.left }}
            transition={spring}
            sx={{
              position: "absolute",
              top: 0,
              height: "100%",
              backgroundColor: "white",
              boxShadow: "0 3px 6px rgba(0, 0, 0, 0.06)",
              px: 2,
              py: 1,
              borderRadius: 9999,
            }}
          />
        )}
      </Box>
    </SwitchContext.Provider>
  )
}

Switch.Item = Item
