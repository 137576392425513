import { useField } from "formik"
import { Box, Text } from "@chakra-ui/react"
import { data } from "currency-codes"
import { SearchableSelect } from "./SearchableSelect"

export const CurrencyField = ({ name, outerCss = {}, ...props }) => {
  const [input, _, helpers] = useField(name)

  return (
    <Box sx={{ ...outerCss }}>
      {!!props?.label && (
        <Text fontWeight={500} mb={2}>
          {props.label}
        </Text>
      )}

      <SearchableSelect
        options={data}
        placeholder="Search currency"
        getOptionValue={(opt) => opt.code}
        getOptionFilter={(options, filterText) =>
          options
            .sort((a, b) => b.countries.length - a.countries.length)
            .filter(
              (opt) =>
                opt.code.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) ||
                opt.currency.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
            )
        }
        getOptionLabel={(opt) => `${opt.code} - ${opt.currency}`}
        {...input}
        onChange={(newValue) => {
          helpers.setValue(newValue)
        }}
        outerSx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...props}
      />
    </Box>
  )
}

export const CurrencyPicker = ({ onChange, ...props }) => {
  return (
    <SearchableSelect
      options={data}
      placeholder="Search currency"
      getOptionValue={(opt) => opt.code}
      getOptionFilter={(options, filterText) =>
        options
          .sort((a, b) => b.countries.length - a.countries.length)
          .filter(
            (opt) =>
              opt.code.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) ||
              opt.currency.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
          )
      }
      getOptionLabel={(opt) => `${opt.code} - ${opt.currency}`}
      onChange={onChange}
      {...props}
    />
  )
}
